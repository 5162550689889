// @ts-nocheck
'use client'

import React, { createContext, useContext, useState } from 'react'

const GlobalContext = createContext(null)

export default function GlobalContextProvider({ locale, children }): JSX.Element {
  const [popup, setPopup] = useState(false)
  const [mobileNav, setMobileNav] = useState(false)
  
  return (
      <GlobalContext.Provider
        value={{
          locale,
          popup,
          setPopup,
          mobileNav,
          setMobileNav
        }}
        >
        {children}
      </GlobalContext.Provider>
  )
}

export function useGlobalContext() {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider"
    )
  }
  return context
}